<template>
    <div class="add_teacher">
      <el-card class="box-card">
        <el-form
          ref="addFromRef"
          :model="addFrom"
          :rules="addFromRules"
          label-width="110px"
        >
        <el-form-item label="等级名称:" v-if="levelTitle">
            <!-- <el-input
              v-model="levelTitle"
              style="width: 300px; margin-right: 10px"
              :disabled="true"
            ></el-input> -->
            {{levelTitle}}
          </el-form-item>
          <el-form-item label="奖励标题:" prop="title">
            <el-input
              v-model="addFrom.title"
              style="width: 300px; margin-right: 10px"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item
            label="达到数量:"
            prop="goalNum"
            v-if="type != 'consumption'"
          >
            <el-input-number
              style="width: 200px; margin-right: 10px"
              v-model="addFrom.goalNum"
              :min="1"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="消费金额:" prop="fee" v-if="type == 'consumption'">
            <el-input
              v-model="addFrom.fee"
              style="width: 300px; margin-right: 10px"
              min="1"
              type="number"
              @blur="blur"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="商品类型:" prop="prodType">
            <!-- 选择框 -->
            <el-select
              v-model="addFrom.prodType"
              @change="getProdInfo"
              placeholder="请选择"
            >
              <el-option
                v-for="item in goodsTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="产品Id:"
            prop="prodId"
            v-if="addFrom.prodType != 1"
          >
            <el-input
              @blur="getProdInfo"
              v-model.number="addFrom.prodId"
              style="width: 300px; margin-right: 10px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="已选:"
            prop="prodId"
            v-if="addFrom.prodType != 1 && selectMsg"
          >
            <span>{{ selectMsg }}</span>
          </el-form-item>
          <el-form-item
            label="奖励金额:"
            prop="goalFee"
            v-if="addFrom.prodType == 1"
          >
            <el-input
              v-model.number="addFrom.goalFee"
              style="width: 300px; margin-right: 10px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="入住天数:"
            prop="roomDay"
            v-if="addFrom.prodType == 4"
          >
            <el-input
              v-model.number="addFrom.roomDay"
              style="width: 300px; margin-right: 10px"
            ></el-input>
          </el-form-item>
          <el-form-item label="奖励数量:" prop="count">
            <el-input
              v-model.number="addFrom.count"
              style="width: 300px; margin-right: 10px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="submitForm">保存</el-button>
            <el-button type="primary" @click="goBack">返回</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </template>
  <script>
  import {
    getUpgradePrizeDetail,
    addUpgradePrize,
    selectUpgradePrizeName
  } from "../../api/wanxiaoShop.js";
  export default {
    components: {},
    data() {
      return {
        selectMsg: "",
        goodsTypeList: [
          {
            label: "现金",
            value: 1,
          },
          {
            label: "优惠券",
            value: 2,
          },
          {
            label: "门票",
            value: 3,
          },
          {
            label: "酒店客房",
            value: 4,
          },
          {
            label: "万旅卡",
            value: 5,
          },
          {
            label: "秒杀产品",
            value: 6,
          },
          {
            label: "餐饮",
            value: 7,
          },
          {
            label: "精选商城",
            value: 8,
          },
          {
            label: "线路",
            value: 9,
          },
        ],
        addFrom: {
          goalId: 0,
          title: "",
        //   goalNum: "",
          prodType: "",
          prodId: "",
          goalFee: 0,
          count: "",
          roomDay: 1,
          levelId:''
        },
        date: [],
        addFromRules: {
          title: [{ required: true, message: "请输入奖励标题", trigger: "blur" }],
        //   goalNum: [
        //     { required: true, message: "请输入达到数量", trigger: "blur" },
        //     //   不能小于1
        //   ],
          prodType: [
            { required: true, message: "请选择商品类型", trigger: "blur" },
          ],
          goalFee: [
            { required: true, message: "请输入奖励金额", trigger: "blur" },
          ],
          count: [{ required: true, message: "请输入奖励数量", trigger: "blur" }],
          roomDay: [
            { required: true, message: "请输入奖励数量", trigger: "blur" },
          ],
          fee: [{ required: true, message: "请输入消费金额", trigger: "blur" }],
        },
        levelTitle: "",
      };
    },
    created() {
      this.addFrom.goalId = this.$route.query.id;
      this.addFrom.levelId = this.$route.query.levelId;
      this.levelTitle = this.$route.query.levelTitle
      if (this.$route.query.id) {
        this.getDetails();
      }
    },
    methods: {
      blur() {
        // if(this.addFrom.fee <=0){
        //   this.addFrom.fee =1;
        // }
        var reg = /^(([1-9]\d*)|([0][.]{1}[0-9]{0,2}[1-9]+)|([1-9]\d*[.]{1}[0-9]+))$/g
        if (!reg.test(this.addFrom.fee)) {
          this.addFrom.fee = 1;
        }
      },
      getProdInfo() {
        if (
          this.addFrom.prodId &&
          this.addFrom.prodType != 1 &&
          this.addFrom.prodType
        ) {
            selectUpgradePrizeName({
            prodId: this.addFrom.prodId,
            prodType: this.addFrom.prodType,
          }).then((res) => {
            if (res.data.code == 0) {
              console.log(res.data.msg);
              this.selectMsg = res.data.msg;
            } else {
              this.$message.warning(res.data.msg);
              this.selectMsg = "";
              this.addFrom.prodId = "";
            }
          });
        }
      },
      handleAvatarSuccess(res, file, type) {
        this.addFrom.headImgUrl = file.response.data[0].src;
      },
      submitForm() {
        console.log(this.addFrom);
        // return
        this.$refs.addFromRef.validate(async (validate) => {
          if (!validate) return;
          if (this.isSubmit) return;
          this.isSubmit = true;
          const { data } = await addUpgradePrize(this.addFrom);
          this.isSubmit = false;
          if (data.code == 0) {
            this.$message.success(data.msg);
            //   this.getDetails();
            this.goBack();
          } else {
            this.$message.warning(data.msg);
          }
        });
      },
      getDetails() {
        getUpgradePrizeDetail({
          goalId: this.addFrom.goalId,
        }).then((res) => {
          if (res.data.code == 0) {
            Object.keys(this.addFrom).forEach((key) => {
              this.addFrom[key] = res.data.data[key];
            });
            this.getProdInfo();
          }
        });
      },
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>
  
  <style scoped>
  .avatar-uploader-icon {
    border: 1px dashed #eee;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
  }
  .box-card {
    padding-bottom: 50px;
  }
  </style>
  